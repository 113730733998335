import { Injectable } from '@angular/core';
import { PanelService } from "@irl_app/services/panel/panel.service";

@Injectable()
export class CompetitivePricingService {
    constructor(
         private panelService : PanelService
    ) { }

    async getUploadedCompetitivePricing(sessionId : number) {
        return (await this.panelService.getCompetitivePricingList(sessionId));
    }

    async clearUploadedCompetitivePricing(sessionId : number) {
        return await this.panelService.clearCompetitivePricingList(sessionId);
    }
}
