import { Injectable } from "@angular/core";
import { MatchedCsvRow } from "@services/lib/panel/panel.service";
import { firstValueFrom } from "rxjs";
import { ISessionPanel } from "@shared/model/session-panel";
import { Service } from "../service/service";
import type { PanelAPI } from "../../../../../services/lib/panel/panel.api";
import { MadErrorUtils } from "../../utils/client-error";

export type UpdateFieldNameType = "price" | "discount" | "qty_estimate";

interface AddPanel {
    lims_code: string;
    match_code: string;
    parent_id?: number;
    session: number | string;
    match_price?: number;
    is_extra?: boolean;
    related_panel_id?: number;
}

@Injectable()
export class PanelService extends Service<typeof PanelAPI> {
    all({ session, session_panel_ids }: { session: number; session_panel_ids?: number[] }) {
        return super.post("/panels/all", { body: { session, session_panel_ids } });
    }

    competitiveHash = {};

    // add a panel
    async add({ lims_code, parent_id, match_code, session, match_price, is_extra, related_panel_id }: AddPanel): Promise<ISessionPanel> {
        try {
            const resp = await firstValueFrom(
                super.post("/panels/add", {
                    body: {
                        lims_code,
                        lims: "LYNXX US",
                        match_code,
                        parent_id,
                        session,
                        match_price,
                        is_extra,
                        related_panel_id
                    }
                })
            );
            if (!resp.success) {
                console.error("panel.service.add failed: ", resp.error);
                throw MadErrorUtils.parseError(resp.error);
            }
            return resp.data;
        } catch (err) {
            if (err.code) {
                throw err;
            }
            console.error("Error calling panel.service.add: ", err);
            throw err;
        }
    }

    // remove a panel
    async remove({ session_panel_id }: { session_panel_id: number }): Promise<ISessionPanel[]> {
        try {
            const resp = await firstValueFrom(
                super.post("/panels/remove", {
                    body: {
                        session_panel_id
                    }
                })
            );
            if (!resp.success) {
                console.error("panel.remove failed: ", resp.error);
                throw MadErrorUtils.parseError(resp.error);
            }
            return resp.data;
        } catch (err) {
            if (err.code) {
                throw err;
            }
            console.error("Error calling panel.remove: ", err);
            throw err;
        }
    }

    public async update({ sessionPanelId, field, value }: { sessionPanelId: number; field: UpdateFieldNameType; value: boolean | string | number }): Promise<ISessionPanel[]> {
        try {
            const resp = await firstValueFrom(
                super.post("/panels/update", {
                    body: {
                        session_panel_id: sessionPanelId,
                        field,
                        value
                    }
                })
            );
            if (!resp.success) {
                console.error("panel.update failed: ", resp.error);
                throw MadErrorUtils.parseError(resp.error);
            }
            return resp.data;
        } catch (err) {
            if (err.code) {
                throw err;
            }
            console.error("Error calling panel.update: ", err);
            throw err;
        }
    }

    public async uploadCompetitiveTestList(file: File, sessionId : number): Promise<MatchedCsvRow[]> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('sessionId', String(sessionId));

        try {
            const resp = await firstValueFrom(
                 super.post("/panels/upload", {
                     body: formData
                 })
            );
            if (!resp.success) {
                console.error("panel.upload failed: ", resp.error);
                throw MadErrorUtils.parseError(resp.error);
            }
            this.setCompetitiveHash(resp.data);
            return resp.data;
        } catch (err) {
            if (err.code) {
                throw err;
            }
            console.error("Error calling panel.upload: ", err);
            throw err;
        }
    }

    public async getCompetitivePricingList(sessionId : number): Promise<MatchedCsvRow[]> {
        const resp = await firstValueFrom(super.get("/panels/upload", { params: { sessionId } }));
        if (!resp.success) {
            console.error("panel.competitveUploadData failed: ", resp.error);
            throw MadErrorUtils.parseError(resp.error);
        }
        this.setCompetitiveHash(resp.data);
        return resp.data;
    }

    public async clearCompetitivePricingList(sessionId : number): Promise<void> {
        const resp = await firstValueFrom(super.delete("/panels/upload", { params: { sessionId } }));
        if (!resp.success) {
            console.error("panel.competitveUploadData failed: ", resp.error);
            throw MadErrorUtils.parseError(resp.error);
        }
        this.setCompetitiveHash([]);
    }

    getMatch(lims_code: string) {
        return this.competitiveHash[lims_code];
    }

    setCompetitiveHash(input = []) {
        this.competitiveHash = input.reduce((acc, item) => {
            acc[item.lims_code] = { ...item };
            return acc;
          }, {});
    }
}
